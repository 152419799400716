import React, { Component } from "react";
import Cropper from "react-easy-crop";

import "./UiModal.scss";
import "./UiModalImageCrop.scss";

import UiModal from "./UiModal";

class UiModalImageCrop extends Component {
  state = {
    aspect: 1 / 1,
    crop: { x: 0, y: 0 },
    croppedAreaPixels: null,
    zoom: 1,
  };

  constructor(props) {
    super(props);
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    this.setState({ croppedAreaPixels: croppedAreaPixels });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  render() {
    const modalInner = (
      <div className="inter-image-crop-overlay">
        <div className="inter-image-crop-wrap">
          <div className="image-crop">
            <Cropper
              image={this.props.base64Image}
              crop={this.state.crop}
              zoom={this.state.zoom}
              aspect={this.props.aspect ? this.props.aspect: this.state.aspect}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}
            />
          </div>
          <button
            onClick={() => this.props.onSave(this.state.croppedAreaPixels)}
          >
            {" "}
            Сохранить{" "}
          </button>
        </div>
      </div>
    );
    return (
      <UiModal
        children={modalInner}
        name="modal-image-crop"
        show={this.props.modalOpen}
      />
    );
  }
}

export default UiModalImageCrop;
