import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addFile, retrieveData } from "../../services/Storage";
import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ReactComponent as SvgClose } from "../../assets/images/ui/close.svg";

import UiInputText from "../../components/inputs/UiInputText";
import UiModalImageCrop from "../../components/modals/UiModalImageCrop";
import UiSelect from "../../components/select/UiSelect";
import UiDropDown from "../../components/inputs/UiDropDown";
import UiTextArea from "../../components/inputs/UiTextArea";
import UiCardAddPhoto from "../../components/cards/UiCardAddPhoto";
import UiButtonColor from "../../components/button/UiButtonColor";
import UiTableAdaptive from "../../components/table/UiTableAdaptive";

import { getCategoryList as getCategoryListAction } from "../../redux/modules/category";
import {
    addMaterial as addMaterialAction,
    update as updateAction
} from "../../redux/modules/material";

import Env from "../../services/Env";
import { addFileBase64 } from "../../services/Storage";
import getCroppedImg from "../../services/CropImage";
import StorageConstant from "../../constants/StorageConstant";

import "./MaterialsStyle.scss";

import { toArrayListKey, toArrayImagesListKey, fromArrayListKey, inArray, inArrayValue, unCheckInArray } from "../../utilities/array";
 

function MaterialAddPage(props) {

    const navigate = useNavigate();
    const [order, setOrder] = useState({
        isEdit: false,

        title: "",
        text: "",
        category: 0,
        type: 0,
        sort: 0,
        price: 0,
        url: "",
        images: [],
    });
    const [state, setState] = useState({
        isLoad: false,
        isDialogCrop: false,
        savedImages: [],
        removeArray: [],
        imagesList: []
    });
    const [additional, setAdditional] = useState([])
    const categorys = [
        {value: 0, text: "Слайдер"},
        {value: 1, text: "Материал"},
    ];

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "white",
        borderRadius: '10px',
        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: grid,
        width: 250
    });


    const clientsTitles = [
        { sort: false, title: "" },
        { sort: false, title: "" },
        { sort: true, title: "Название" },
        { sort: false, title: "Цена" },
    ];



    useEffect(() => {
     
        let editData = retrieveData("edit");
        if (editData) {
            let imgs = toArrayImagesListKey(editData.images);
            setState({ ...state, imagesList: imgs })
            editData.images = imgs;
            editData.isEdit = true;
            setOrder(editData);
        }
    }, []);

    function uploadList(file) {
        addFile(file, 2).then((res) => {
            if (res.success) {
                setState({ ...state, addPhoto: res.response })
            }
        });
    }


    function onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            state.imagesList,
            result.source.index,
            result.destination.index
        );

        setState({
            ...state,
            imagesList: items
        });
    }

    /**
     * 
     * @param {Array} _arr 
     * @returns 
     */
    function _convertImgs(_arr) {
        let arr = [];
        _arr.map((item, index) => {
            arr.push({ id: `item-${index}`, content: item });
        })
        return arr;
    }


    /**
     *
     * @param {string} key
     * @param {any} val
     */
    function updateOrderObj(key, val) {
        let _obj = order;
        _obj[key] = val;
        setOrder({ ..._obj });
    }

    function _onSaveImage(_croppedAreaPixels) {
        setState({ ...state, isDialogCrop: false });
        getCroppedImg(state.savedImages[0], _croppedAreaPixels, 0).then((res) => {
            setState({ ...state, selectedFileName: res, });
            let arr = state.savedImages;
            arr.splice(0, 1);
            setState({ ...state, savedImages: arr });
            upload(res);

        });
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    }

    function upload(file) {
        setState({
            ...state, imageLoad: true
        });
        addFileBase64(file, 0).then((res) => {
            setState({
                ...state, imageLoad: false
            });
            if (res.success) {
                let arr = state.imagesList;
                arr.push({ id: (arr.length + 1).toString(), content: res.response });
                setState({
                    ...state,
                    imagesList: (arr)
                })

                console.log(state.savedImages, arr);

                setState({
                    ...state, isDialogCrop: state.savedImages.length > 0
                });
            } else {
                console.log(res);
            }
        });
    }

    function _remImg(index, id) {
        let arr = state.imagesList;
        let _removeArray = state.removeArray;
        _removeArray.push(id);
        arr.splice(index, 1);
        setState({
            ...state,
            removeArray: _removeArray,
            imagesList: (arr)

        });
        updateOrderObj("imgs", arr);
    }



    return (
        <div id="page" className="product-add-page">
            <div className="product-add-wrap">
                <div className="product-add-about add">
                    <div className="product-add-about-title">
                        <div className="product-add-about-title-wrap">
                            <h3>Добавить материал</h3>
                        </div>
                    </div>
                    <div className="product-add-notes">
                        <div className="product-add-notes-list-wrap">
                            <div className="product-add-info">
                                <div className="product-add-info-form">
                                    <UiInputText
                                        label="Название материала:"
                                        placeholder="Название материала"
                                        value={order.title}
                                        onChange={(val) => {
                                            updateOrderObj("title", val);
                                        }}
                                    />
                                </div>
                               
                                <div className="product-add-info-form">
                                    <UiTextArea
                                        label="Краткое описание"
                                        value={order.text}
                                        onChange={(val) => updateOrderObj("text", val)} />
                                </div>

                                <div className="product-add-info-form">
                                    <UiDropDown
                                        label="Категория:"
                                        items={categorys}
                                        onChange={(val) => updateOrderObj("category", val)}
                                    />
                                </div>

                                <div className="product-add-info-form">
                                    <UiInputText
                                        label="Ссылка на товар (кнопка ПОДРОБНЕЕ)"
                                        value={order.url}
                                        onChange={(val) => updateOrderObj("url", val)}
                                    />
                                </div>
                              
                              {/*
                                <div className="product-add-info-form">
                                    <UiInputText
                                        label="Цена"
                                        value={order.price}
                                        onChange={(val) => updateOrderObj("price", val)}
                                    />
                                </div>
                            */}
                             

                       

                                <div className="product-add-info-form">
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    {state.imagesList.map((item, index) => (
                                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >

                                                                    <UiCardAddPhoto
                                                                        key={index}
                                                                        image={Env.PUBLIC_URL + item.content}
                                                                        remove={() => {
                                                                            _remImg(index, item.content)
                                                                        }}
                                                                    />



                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>

                                <div className="product-add-info-form product-add-info-photo">
                                    <UiCardAddPhoto
                                        upload={(e) => {
                                            if (e.target.files.length > 0) {
                                                for (let i = 0; i < e.target.files.length; i++) {
                                                    let arr = state.savedImages;
                                                    getBase64(e.target.files[i], (result) => {
                                                        arr.push(result);
                                                        setState({
                                                            ...state,
                                                            savedImages: arr,
                                                            base64Image: result,
                                                            isDialogCrop: true,
                                                        });
                                                    });
                                                }
                                            }
                                        }}
                                    />
                                </div>

                                <div className="product-add-info-form product-add-info-photo">
                                    <UiButtonColor
                                        color="primary"
                                        text={order.isEdit ? "Редактировать" : "Создать"}
                                        small={true}
                                        onClick={() => {
                                            console.log(order, state.imagesList)
                                            if (
                                                state.imagesList.length > 0  
                                          
                                            ) {
                                                if (order.isEdit) {
                                                    let _order = order;
                                                    _order.additional = additional;
                                                    _order.remove = state.removeArray;
                                                    _order.images = fromArrayListKey(state.imagesList, "content"); 
                                                    props.update(_order).then((res) => { 
                                                        if (res) navigate(`/material/list`);
                                                    });

                                                } else {
                                                    let _order = order;
                                                    _order.additional = additional;
                                                    _order.images = fromArrayListKey(state.imagesList, "content");
                                                    console.log(_order);
                                                    props.addMaterial(_order).then((res) => {
                                                        if (res) navigate(`/material/list`);
                                                    });
                                                }

                                            } else {
                                                alert("Заполните поля название и описание");
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
 
            </div>


            <UiModalImageCrop
                aspect={10/4}
                modalOpen={state.isDialogCrop}
                base64Image={state.savedImages[0]}
                onSave={(val) => _onSaveImage(val)}
                cancelClick={() => setState({ ...state, isDialogCrop: false })}
            />
        </div>
    );
}

export default connect(
    ({ material }) => ({ material }),
      {
        addMaterial: addMaterialAction,
        update: updateAction
      }
)(MaterialAddPage);
